<template>
  <div class="main">
    <div class="itemNameBox">
      <div class="itemName">
        <el-divider>
          <div class="dividerFont">
            <span>个人设置</span>
            <div
              style="width: 60px; height: 2px; background-color: #029aff"
            ></div>
          </div>
        </el-divider>
      </div>
      <div class="myInfoBox">
        <img
          class="photo"
          v-if="userInfo.avatar"
          :src="userInfo.avatar"
          alt=""
        />
        <img v-else class="photo" src="@/assets/img/noAvatar.png" alt="" />
        <!-- @/assets/personCenter/resumeManagement/photo.png -->
        <ul class="textInfoBox">
          <li class="userNum">
            <span class="textL">姓名</span>
            <span>{{ certificates.name }}</span>
            <div class="icon">
              <i
                class="iconfont icon-shiming"
                style="font-size: 24px"
                v-if="certificates.name && certificates.audit_status == 2"
              ></i
              ><span v-if="certificates.name && certificates.audit_status == 2"
                >已实名</span
              >
              <i
                class="el-icon-loading"
                style="color: blue; margin-left: 30px"
                v-if="
                  certificates.audit_status == 4 ||
                  certificates.audit_status == 1
                "
              ></i>
              <span
                style="color: blue"
                v-if="
                  certificates.audit_status == 4 ||
                  certificates.audit_status == 1
                "
                >认证中</span
              >
              <span style="color: red" v-if="certificates.audit_status == 3"
                >已驳回，请重新认证</span
              >
            </div>
          </li>
          <li>
            <span class="textL">账号</span>
            <span>{{ userInfo.username }}</span>
          </li>
          <li>
            <span class="textL">年龄</span>
            <span>{{ certificates.age }}</span>
          </li>
          <li>
            <span class="textL">身份证号</span>
            <span>{{ certificates.identity_code }}</span>
          </li>
          <li>
            <span class="textL">联系方式</span>
            <span>{{ userInfo.phone }}</span>
          </li>
          <!-- <li>
            <span class="textL">邮箱</span>
            <span>{{ userInfo.email }}</span>
          </li> -->
        </ul>
        <div class="editBtn" @click="toEdit">修改资料</div>
      </div>
      <div class="vipLevelBox">
        <div class="vipLeft">
          <div class="level">
            <span>权益中心</span>
            <img src="" alt="" />
          </div>
          <!-- <span>专属六大权益</span> -->
        </div>
        <el-divider direction="vertical"></el-divider>
        <div class="vipRight" @click="toMember">
          <div class="recharge">
            <i
              class="iconfont icon-qianbao"
              style="font-size: 24px; margin-right: 20px"
            ></i>
            <span>去充值</span>
          </div>
          <!-- <span>查看详情</span> -->
        </div>
      </div>
      <!-- <div class="vipLevelBox">
        <div class="vipLeft">
          <div class="level">
            <span>黄金会员</span>
            <img src="" alt="" />
          </div>
          <span>专属六大权益</span>
        </div>
        <el-divider direction="vertical"></el-divider>
        <div class="vipRight">
          <div class="recharge">
            <i
              class="iconfont icon-qianbao"
              style="font-size: 24px; margin-right: 20px"
            ></i>
            <span>去充值</span>
          </div>
          <span>查看详情</span>
        </div>
      </div> -->
      <div class="btnsBox">
        <div class="btn" @click="toAuthentication">
          <i
            class="iconfont icon-gerenrenzheng"
            style="font-size: 36px; margin-right: 16px"
          ></i>
          <span>认证中心</span>
        </div>

        <div class="btn" @click="showPop ">
          <i
            class="iconfont icon-fankui"
            style="font-size: 24px; margin-right: 16px"
          ></i>
          <span>建议反馈</span>
        </div>

        <div class="btn">
          <i
            class="iconfont icon-bangzhuwenti"
            style="font-size: 22px; margin-right: 16px"
          ></i>
          <span>常见问题</span>
        </div>
      </div>
      <div class="longDivider">
        <el-divider></el-divider>
      </div>
      <div class="certificatesBox">
        <div class="certificatesItem">
          <p>身份证件</p>
          <img :src="$imgUrl(certificates.identity_card1)" :onerror="$global.srcImgUrl" alt="" />
          <!-- <img src="@/assets/personCenter/image.png.png" alt="" /> -->
        </div>
        <div class="certificatesItem">
          <p>&emsp; &ensp;</p>
          <div style="height: 35px"></div>
          <img :src="$imgUrl(certificates.identity_card0)" :onerror="$global.srcImgUrl" alt="" />
          <!-- <img src="@/assets/personCenter/image.png2.png" alt="" /> -->
        </div>
        <div class="certificatesItem">
          <p>学历证书</p>
          <img
            v-if="certificates.degree_certificate"
            :src="$imgUrl(certificates.degree_certificate)"
            :onerror="$global.srcImgUrl"
            alt=""
          />
          <img v-else src="@/assets/img/null.png" alt="" />
        </div>
        <div class="certificatesItem">
          <p>资格证书</p>
          <img
            v-if="certificates.qualification_certificate"
            :src="$imgUrl(certificates.qualification_certificate)"
            :onerror="$global.srcImgUrl"
            alt=""
          />
          <img v-else src="@/assets/img/null.png" alt="" />
        </div>
        <div class="certificatesItem">
          <p>职称证书</p>
          <img
            v-if="certificates.title_certificate"
            :src="$imgUrl(certificates.title_certificate)"
            :onerror="$global.srcImgUrl"
            alt=""
          />
          <img v-else src="@/assets/img/null.png" alt="" />
        </div>
        <div class="certificatesItem">
          <p>工作证明</p>
          <img v-if="certificates.pow" :src="$imgUrl(certificates.pow)" :onerror="$global.srcImgUrl" alt="" />
          <img v-else src="@/assets/img/null.png" alt="" />
        </div>
      </div>
    </div>

    <el-dialog title="建议反馈" lock-scroll  :visible.sync="dialogFormVisible">
      <el-form
        :model="form"
        label-position="top"
        style="padding: 0 20px"
        class="suggestions"
      >
      <div class="form-list" @click="dialogList">我的反馈</div>
        <el-form-item label="反馈内容" label-width="120px">
          <el-input
            type="textarea"
            placeholder="请简要描述您的问题和意见，一遍我们提供更好的帮助"
            v-model="form.content"
            maxlength="300"
            show-word-limit
            :autosize="{ minRows: 4 }"
          ></el-input>
        </el-form-item>
        <el-form-item label="图片上传" label-width="120px">
          <el-upload
            ref="uploadInfo"
            name="uploadfile"
            :action="$global.uploadAction"
            :before-upload="beforeUpload"
            list-type="picture-card"
            :file-list="fileList"
            :limit="4 "
            :on-success="handleAvatarSuccess"
            :on-remove="handleRemove"
          >
            <i slot="default" class="el-icon-plus"></i>
            <!-- <div slot="file" slot-scope="{ file }">
              <img
                class="el-upload-list__item-thumbnail"
                :src="file.url"
                alt=""
              />
              <span class="el-upload-list__item-actions">
                <span
                  class="el-upload-list__item-preview"
                  @click="handlePictureCardPreview(file)"
                >
                  <i class="el-icon-zoom-in"></i>
                </span>
                <span
                  v-if="!disabled"
                  class="el-upload-list__item-delete"
                  @click="handleDownload(file)"
                >
                  <i class="el-icon-download"></i>
                </span>
                <span
                  v-if="!disabled"
                  class="el-upload-list__item-delete"
                  @click="handleRemove(file)"
                >
                  <i class="el-icon-delete"></i>
                </span>
              </span>
            </div> -->
          </el-upload>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="save">提 交</el-button>
      </div>
      <el-dialog
        title="反馈记录"
        :modal="false"
        :visible.sync="dialogListShow"
        width="50%"
        lock-scroll
       >
        <!-- :before-close="handleClose" -->
        <div v-if="feedbackList.length > 0" class="feedback-box">
            <div class="feedback" v-for="(item,index) in feedbackList" :key="index">
              <div>反馈内容:</div>
              <div class="feed-content">{{ item.content }}</div>
              <div>反馈图片:</div>
              <div v-if="item.img" class="feed-imgList">
                <img class="feed-img" :src="image" v-for="(image,idx) in item.img.split(',')" :key="idx" alt="">
              </div>
              <div>反馈回复:</div>
              <div class="feed-content" v-if="item.feedbackList.length >0">
                {{ item.feedbackList[0].content }}
              </div>
            </div>
        </div>
        <el-empty v-else description="空空如也"></el-empty>
        <el-pagination
            v-if="feedbackList.length > 0"
            background
            layout="prev, pager, next"
            :total="totalRows"
            :current-page="totalList"
            :page-size="5"
            @current-change="chenPagination"
          >
          </el-pagination>
      </el-dialog>
    </el-dialog>
  </div>
</template>

<script>
import { getLoginUser } from '@/api/login/login'
import { getJobUserAuth } from '@/api/jobInformation/jobInformation'
import { jobFeedbackAdd,jobFeedbackPage } from '@/api/personalCenter/personalCenter'
import moment from 'moment'
export default {
  name: '',
  data() {
    return {
      imgPath: '',
      userInfo: {}, //个人信息
      certificates: {}, //证书信息
      dialogFormVisible: false,
      dialogListShow: false,
      form: {
        createUser: '', //反馈用户id
        content: '', //内容
        img: '', //图片,逗号分隔
      },
      fileList: [],
      urls: [],
      feedbackList: [],
      totalList: 1,
      totalRows: 0,
      params:{
        pageNo: 1,
        pageSize: 5
      }
    }
  },
  watch: {},
  props: {},
  components: {},
  computed: {},
  created() {
    this.getUserInfo()
    this.getJobUserAuthForId()
  },
  mounted() {},
  methods: {
    toMember() {
      this.$router.push('/personalCenter/memberCenter')
    },
    save() {
      if(this.form.content){
        let arr = []
      this.urls.forEach((i) => arr.push(i.response.msg))
      this.form.img = arr.join(',')
      this.form.createUser = localStorage.getItem('userId')
      jobFeedbackAdd(this.form).then((res) => {
        if (res.success) {
          this.$message({
            message: '提交成功',
            type: 'success',
          })
          this.urls = []
          this.form.content = ''
          this.dialogFormVisible = false
        } else {
          this.$message.error('提交失败，请重试')
        }
      })
      }else{
        this.$message.error('请填写反馈内容')
      }
    },
    showPop(){
      this.urls = []
      this.form.content = ''
      this.dialogFormVisible = true
      this.$refs.uploadInfo.clearFiles();
    },
    dialogList(){
      this.dialogListShow = !this.dialogListShow
      this.dialogListPage()
    },
    dialogListPage(){
      let createUser = localStorage.getItem('userId')
      this.params.createUser = createUser
      jobFeedbackPage(this.params).then((res) => {
        if (res.success) {
          this.feedbackList = res.data.rows
          this.totalRows = res.data.totalRows;
        }
      })
    },
    chenPagination(cur) {
      this.params.pageNo = cur;
      this.dialogListPage();
    },
    handleAvatarSuccess(res, file, fileList) {
      // console.log(res, 'res')
      // console.log(file, 'file')
      // console.log(fileList, 'fileList')
      if (res) {
        this.urls.push(file);
      }
    },
    handleRemove(file) {
      this.urls = this.urls.filter((i) => i.url != file.url)
    },
    // 上传文件判断
    beforeUpload(file) {
      const isJPG = file.type === "image/jpeg" || file.type === "image/png";
      const isLt2M = file.size / 1024 / 1024 < 5;

      if (!isJPG) {
        this.$message.error("上传图片只能是 .jpg .png 格式!");
      }
      if (!isLt2M) {
        this.$message.error("上传图片大小不能超过 5MB!");
      }
      return isJPG && isLt2M;
    },
    // 个人信息
    getUserInfo() {
      getLoginUser().then((res) => {
        this.userInfo = res.data
      })
    },
    // 查询认证信息
    getJobUserAuthForId() {
      getJobUserAuth().then((res) => {
        // if(res.data.audit_status == 3) {
        //   return
        // }
        if (res.data) {
          this.certificates = res.data
          let age = this.certificates.identity_code.substring(6, 10)
          this.certificates.age =
            moment(new Date()).format('YYYY') - moment(age).format('YYYY')
        } else {
          this.certificates.age = ''
        }
      })
    },
    toEdit() {
      this.$router.push('editPersonalSettings')
    },
    toAuthentication() {
      this.$router.push({ path: '/prise', query: { from: 'setting' } })
    },
  },
  filters: {},
}
</script>

<style scoped lang="scss">
ul,
li {
  list-style: none;
  margin: 0;
  padding: 0;
}

.itemNameBox {
  min-height: 500px;
  background-color: #fff;
  padding-top: 20px;
  padding-bottom: 60px;
  // padding: 20px 50px 60px 50px;
  .itemName {
    // padding: 0 43px;
    padding: 0px 86px;
    padding-bottom: 22px;
    .dividerFont {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin: 0 50px;
      span {
        font-size: 24px;
        margin-bottom: 6px;
      }
    }
  }
}

.myInfoBox {
  padding: 0 75px;
  display: flex;
  .photo {
    width: 160px;
    height: 160px;
    object-fit: cover;
    margin-right: 60px;
  }
  .textInfoBox {
    flex: 1;
    display: flex;
    flex-direction: column;
    li {
      font-size: 18px;
      margin-bottom: 15px;
      .textL {
        display: inline-block;
        width: 80px;
        text-align-last: justify;
        position: relative;
        margin-right: 30px;
      }
      .textL::after {
        position: absolute;
        right: -6px;
        content: ':';
      }
      span:nth-child(2) {
        color: #666;
      }
    }
    .userNum {
      display: flex;
      .icon {
        margin-left: 42px;
        font-size: 12px;
        display: flex;
        align-items: center;
        color: #ff8d19;
        span {
          color: #ff8d19;
          margin-left: 10px;
        }
      }
    }
  }
  .editBtn {
    width: 100px;
    height: 32px;
    text-align: center;
    line-height: 32px;
    font-size: 18px;
    border: 1px solid #029aff;
    border-radius: 3px;
    color: #029aff;
    margin-right: 42px;
    cursor: pointer;
  }
}

.vipLevelBox {
  margin: 0 75px;
  height: 120px;
  margin-top: 20px;
  background: url('~@/assets/personCenter/vipBgc.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .vipLeft {
    margin-left: 45px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: 18px;
    background-image: linear-gradient(90deg, #ffd6ba, #ffc4a1);
    background-clip: text;
    -webkit-background-clip: text;
    color: transparent;
    .level {
      font-size: 24px;
      font-weight: bold;
      margin-bottom: 10px;
    }
  }
  .el-divider {
    height: 60px;
    margin-right: 46px;
    margin-left: auto;
  }
  .vipRight {
    margin-right: 45px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    font-size: 18px;
    color: #fff;
    .recharge {
      margin-bottom: 10px;
      cursor: pointer;
    }
    span {
      cursor: pointer;
    }
  }
}

.btnsBox {
  padding: 0 75px;
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
  font-size: 18px;
  .btn {
    width: 256px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f2faff;
    color: #029aff;
    border-radius: 3px;
    cursor: pointer;
  }
  .btn span:hover {
    text-decoration: underline;
  }
}

.longDivider {
  // margin: 30px 0;
  // margin: 0 75px;
  padding: 0 75px;
  .el-divider {
    margin: 30px 0;
  }
}

.certificatesBox {
  padding: 0 75px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  .certificatesItem {
    width: 45%;
    img {
      width: 250px;
      height: 160px;
      object-fit: contain;
      margin-left: 60px;
    }
  }
  .certificatesItem:nth-last-child(1) {
    img {
      // height: 260px;
    }
  }
}
.form-list{
  text-align: right;
  color: #029aff;
  cursor:pointer;
}
.feedback-box{
  height: 500px;
  overflow: hidden;
  overflow-y: scroll;
  margin-bottom: 20px;
  .feedback{
    margin-bottom: 20px;
    background-color: #f5f5f5;
    box-sizing: border-box;
    padding: 10px 20px;
    .feed-content{
      text-indent: 60px;
    }
    .feed-img{
      width: 120px;
      height: 120px;
      margin: 15px 15px 15px 0;

    }
  }
}
  .feedback-box::-webkit-scrollbar {
    width: 0 !important;
  }
  .feedback-box {
    -ms-overflow-style: none;
  }
  .feedback-box {
    overflow: -moz-scrollbars-none;
  }

</style>
